import { Bet, Choice } from "../store/models/GameInstanceModel";
import { Amount } from "../common";
import { Constructor } from "../helpers/Constructor";
import { IServerResponse } from "./MiddleLayer";


interface ICard {
	CardId: number
	Abbr: string
}

interface ServerPaiGowBet {
	WagerMin: {
		Category: "currency"
		Type: "chip"
		Quantity: number
	}
	WagerMax: {
		Category: "currency"
		Type: "chip"
		Quantity: number
	},
	Payout: number
}

interface PaiGowHand {
	HandId:number

	Cards: ICard[],
	Score: number
	Completed: boolean
	Wagers: any
	CardScores: number[]
	Natural: boolean
	Pair: boolean
}

interface HistoryEntry {

}

interface ServerPaiGowRulesResponse extends IServerResponse {
	Payload: {
		Rules:ClientPaiGowRulesResponse
	}
}

export interface ClientPaiGowRulesResponse {
	Rules: {
		RulesId: number
		CreatedTime: number
		DeletedTime: number
		Seats: number
		Decks: number
		Ante: ServerPaiGowBet
		SideBetRules: {
			Mega: ServerPaiGowBet
		}
	}
}

interface Wager {
	WagerId: number,
	Amount: Amount,
	Resolution: {
		Type: "payout" | "resolution" | "push"
		Amount: Amount
	}
}

interface Seat {
	SeatNum: number,
	Hand: PaiGowHand
	Score: number
	Wagers: {
		banker: Wager,
		Player: Wager,
		kill_ox_tiger: Wager
		ox_6: Wager,
		tiger_7: Wager
	}
}

interface PaiGowChoiceServerResponse extends IServerResponse {
	Payload: PaiGowChoiceResponsePayload
}

export interface PaiGowChoiceResponsePayload {
	GameId: number
	State:string
	BankerHand: PaiGowHand
	PlayerHand: PaiGowHand
	Seats: Seat[]
	Choices: [
		[]
	]
	WaitingOnWagerIds: any
	Game: any
}

export interface ServerPaiGowCreateResponse extends IServerResponse {
	"Payload": {
		"GameId": 555,
		"State": "running",
		"DealerCards": ICard[],
		"PlayerCards": ICard[]
		"HouseWayHigh": [
			number,
			number,
			number,
			number,
			number
		],
		"HouseWayLow": [
			number,
			number
		]
  }
}



export interface IPaiGowResolveResponse extends IServerResponse {
	Payload: {
		GameId: number
		State: string
		DealerCards: ICard[]
		PlayerCards: ICard[]
		DealerSplitHigh: [number, number, number, number, number]
		DealerHighRank: number
		DealerSplitLow: [number, number]
		DealerLowRank: number
		PlayerSplitHigh: [number, number, number, number, number]
		PlayerHighRank: number
		PlayerSplitLow: [number, number]
		PlayerLowRank: number
		HighOutcome: "lose" | "win"
		LowOutcome:  "lose" | "win"
		MegaOutcome: "three-pair" | 'straight' | 'none'
		HighWinnings: number
		LowWinnings: number
		MegaWinnings: number
	}
}



export function PaiGowMiddleMixin<TBase extends Constructor>(Base: TBase){

	return class extends Base {
		GetPaiGowRules = (rulesId:number) => {

			const stubResponse:ClientPaiGowRulesResponse = {
				Rules: {
					RulesId: rulesId,
					CreatedTime: Date.now(),
					DeletedTime: 0,
					Seats: 1,
					Decks: 6,
					Ante: {
						WagerMin: {
							Category: "currency",
							Type: "chip",
							Quantity: -5
						},
						WagerMax: {
							Category: "currency",
							Type: "chip",
							Quantity: -1000
						},
						Payout: 100000
					},
					SideBetRules: {
						Mega: {
							WagerMin: {
								Category: "currency",
								Type: "chip",
								Quantity: -5
							},
							WagerMax: {
								Category: "currency",
								Type: "chip",
								Quantity: -10000
							},
							Payout: 100000
						}
					}
				}
			}
			return Promise.resolve(stubResponse);

			// //@ts-ignore
			// return this.connection.CallRoute('paigow-rules', {
			// 	"RulesId": rulesId
			// })
			// .then((response:ServerPaiGowRulesResponse) => {
			// 	var formatted:ClientPaiGowRulesResponse = response.Payload;
			// 	return formatted;
			// })
		}

		_PaiGowRebet:any = null;

		PaiGowCreateGame = (rulesId:number, bets:Bet[]) => {
			var choices:any = [];
			bets.forEach(b => {
				choices.push({
					"SeatNum": 1,
					"HandId": 1,
					"BetName": b.name,
					"Type": b.amount ? "bet" : "pass",
					"Amount": {
						"Category": "currency",
						"Type": "chip",
						"Quantity": -b.amount
					}
				});
			})

			const payload = {
				"MegaAmount": {"Category": "currency", "Type": "chip", "Quantity": -bets[0].amount},
				"AnteAmount": {"Category": "currency", "Type": "chip", "Quantity": -bets[1].amount},
				"Game": {
					"RulesId": rulesId,
				}
			};

			this._PaiGowRebet = payload;

			return this.connection.CallRoute('paigow-create-game', payload)
			.then((response:ServerPaiGowCreateResponse) => {
				return response;
			});
		}

		PaiGowSubmitSplit = (gid:number, low:number[], high:number[]) => {
			const Payload = {
				"GameId": gid,
				"SplitHigh": high,
				"SplitLow": low
			}
			return this.connection.CallRoute('paigow-resolve-game', Payload)
			.then((response:IPaiGowResolveResponse) => {
				return response;
			})
			.catch(() => {
				console.error('we are here');
			});
		}

		PaiGowRebet = (rulesId:number) => {
			if(!this._PaiGowRebet){
				console.warn('Attempted to rebet PaiGow with no data.');
				return Promise.reject();
			}
			var payload:any = this._PaiGowRebet;

			return this.connection.CallRoute('paigow-create-game', payload)
			.then((response:ServerPaiGowCreateResponse) => {
				return response;
			});
		}

		GetPaiGowGameState = (id:number) => {
			return this.connection.CallRoute('paigow-game', {
				"GameId": id
			});
		}

		PaiGowChoice = (id:number, choice:Choice) => {
			return this.connection.CallRoute('paigow-choice', {
				"GameId": id,
				Choices: [choice]
			});
		}
	}//end class

}


//Remove allowed, join, leave, set-invite
//Tournament Type: QT, FT