import React from 'react';
import { ModalCover, Modal } from './Modals';
import { store } from '../store';

export class DemoModal extends React.Component{
	render(){
		return (
			<ModalCover onClick={store.closeModal}>
				<Modal>Mooo!</Modal>
			</ModalCover>
		)
	}
}