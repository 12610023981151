import React, { MouseEvent } from 'react';
import styled from 'styled-components';
import { closeModal } from './Modals';
import { RouteComponentProps, NavLink } from 'react-router-dom';
import posed from 'react-pose';

const MenuPositioner = posed(styled.div`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(255,255,255,0.5);
`)({
	open: { opacity: 1, beforeChildren: true, transition:{ duration: 250} },
	closed: { opacity: 0 }
 });

const MenuBody = posed(styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: -320px;
	width: 320px;
	background-color: #012240;
	box-shadow: 0 30px 30px rgba(0,0,0,0.5);
	box-sizing: border-box;
	padding: 16px;
	overflow: auto;
`)({
	closed: {
		x: -320
	},
	open: {
		beforeChildren: true,
		staggerChildren: 100,
		transition: {
			type: 'tween',
			ease: 'easeInOut',
			duration: 250
		},
		x: 320
	}
})

const StyledLink = styled(NavLink)`
	padding: 8px 16px;
	border-radius: 5px;
	background-color: white;
	color: black;
	display: block;
	margin-bottom: 16px;
	text-decoration: none;
	font-size: 16px;
`

const NavItem = posed.div({
	open: { opacity: 1, y: 0 },
	closed: { opacity: 0, y: 30 }
})

const initialState = {
	pose: 'closed'
};
type State = Readonly<typeof initialState>

interface DemoMenuProps extends RouteComponentProps {
	pose: any
}

export class DemoMenu extends React.Component<DemoMenuProps>{

	readonly state: State = initialState;

	componentDidMount(){
		this.setState({pose: 'open'});
	}

	prevent(e:MouseEvent){
		e.stopPropagation();
	}

	render(){
		console.log(this.props.pose);
		const navItems = [
			{url: '/blackjack', name: "Blackjack"},
			{url: '/tcp', name: "TCP"},
			// {url: '/baccarat', name: "Baccarat"},
			{url: '/daibacc', name: "Dai Bacc"},
			{url: '/paigow-portrait/2', name: "Pai Gow"},
			{url: '/paigow-portrait/4', name: "Open Hand Pai Gow"},
		];
		const activeStyle = {
			backgroundColor: 'yellow'
		};
		return (
			<MenuPositioner pose={this.state.pose} onClick={closeModal}>
				<MenuBody onClick={this.prevent}>
					{navItems.map(({ url, name }, i) => (
						<NavItem key={i}><StyledLink to={url} activeStyle={activeStyle}>{name}</StyledLink></NavItem>
					))}
				</MenuBody>
			</MenuPositioner>
		)
	}
}