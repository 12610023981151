import { ClientRulesResponse, Bet, Choice } from "../store/models/GameInstanceModel";
import { NewGameChoice, Amount } from "../common";
import { Constructor } from "../helpers/Constructor";
import { IServerResponse } from "./MiddleLayer";


interface Card {
	CardId: number
	Abbr: string
}

interface BaccaratHand {
	Cards: [Card, Card, Card] | null,
	Score: number
}

interface ServerBaccaratRulesResponse extends IServerResponse {
	Payload: {
		GameId:number,
		BankerHand: BaccaratHand
		PlayerHand: BaccaratHand,
		Seats: {
			"SeatNum": number,
			"Wagers": {}
		}[]
		Choices: NewGameChoice[][][]
	}
}

interface Wager {
	WagerId: number,
	Amount: Amount,
	Resolution: {
		Type: string //"payout"
		Amount: Amount
	}
}

interface Seat {
	SeatNum: number,
	Hand: BaccaratHand
	Score: number
	Wagers: {
		banker: Wager,
		banker_pair: Wager,
		player: Wager
		player_pair: Wager,
		tie: Wager
	}
}

export interface BaccaratChoiceServerResponse extends IServerResponse {
	Payload: {
		GameId: number
		State:string
		BankerHand: BaccaratHand
		PlayerHand: BaccaratHand
		Seats: Seat[]
		Choices: [
			[]
		]
		WaitingOnWagerIds: any
		Game: any
	}
}

interface ClientBaccaratRulesResponse extends ClientRulesResponse {
	gameId: number,
}

export function BaccaratMiddleMixin<TBase extends Constructor>(Base: TBase){

	return class extends Base {
		GetBaccaratRules = (rulesId:number) => {
			//@ts-ignore
			return this.connection.CallRoute('baccarat-create-game', {
				"Game": {
					"RulesId": rulesId
				}
			})
			.then((response:ServerBaccaratRulesResponse) => {
				var seats = response.Payload.Choices.map(seat => {
					var id = seat[0][0].SeatNum;
					var initialHandId = seat[0][0].HandId;
					var bets = seat.map(bet => {
						return {
							name: bet[0].BetName,
							min: Math.abs(bet[0].AmountMin.Quantity),
							max: Math.abs(bet[0].AmountMax.Quantity),
							choices: bet
						}
					})
					return {
						id: id,
						initialHandId: initialHandId,
						isPlayerPosition: true,
						bets: bets
					}
				});

				var formattedResponse:ClientBaccaratRulesResponse = {
					seats: seats,
					gameId: response.Payload.GameId
				}
				return formattedResponse;
			});

		}

		BaccaratCreateGame = (gameId:number, bets:Bet[]) => {
			var choices:any = [];
			bets.forEach(b => {
				choices.push({
					"SeatNum": 1,
					"HandId": 1,
					"BetName": b.name,
					"Type": b.amount ? "bet" : "pass",
					"Amount": {
						"Category": "currency",
						"Type": "chip",
						"Quantity": -b.amount
					}
				});
			})

			return this.connection.CallRoute('baccarat-choice', {
				"GameId": gameId,
				"Choices": choices
			})
			.then((response:BaccaratChoiceServerResponse) => {
				// var p = response.Payload;
				// var formatted:BaccaratGameState = {
				// 	State: p.State,
				// 	BankerHand: p.BankerHand,
				// 	Seats: p.Seats.map(s => ({

				// 		SeatNum: s.SeatNum,
				// 		Hands: [s.Hand]
				// 	}))


				// };
				// return formatted;
				return response.Payload;
			});
		}

		GetBaccaratGameState = (id:number) => {
			return this.connection.CallRoute('baccarat-game', {
				"GameId": id
			});
		}

		BaccaratChoice = (id:number, choice:Choice) => {
			return this.connection.CallRoute('baccarat-choice', {
				"GameId": id,
				Choices: [choice]
			});
		}
	}//end class

}


//Remove allowed, join, leave, set-invite
//Tournament Type: QT, FT