import {
	observable,
	action,
	toJS,
	computed
} from "mobx";

import { GameTypeModel } from "./models/GameTypeModel";
import { Component } from "react";
import { history } from "../history";
// import { TCPGameModel } from "./models/TCPGameModel";
import { GameInstanceModel } from "./models/GameInstanceModel";

//@ts-ignore
window.toJS = toJS;

export class MainStore {

	@observable errorMessage = '';

	@observable commCount = 0;

	@observable message = 'Hello World';

	@observable modal:{component: Component | null, canBackOut: boolean} = {
		component: null,
		canBackOut: true,
	};

	@observable selectedChip = '1';

	chipValues:{[s: string]: number} = {
		'1': 1,
		'5': 5,
		'25': 25,
		'100': 100,
		'500': 500,
		'1k': 1000
	};

	@computed get selectedChipValue():number{
		return this.chipValues[this.selectedChip];
	}

	@observable user = {
		id: 1
	}

	getGameById = (id: string): GameTypeModel | undefined => {
		return this.games.find(g => g.id === id);
	}

	@action setModal = (component: Component, canBackOut:boolean = true) => {
		this.modal.component = component;
		this.modal.canBackOut = canBackOut;
	}

	@action closeModal = () => {
		history.goBack();
	}

	@observable games:GameTypeModel[] = [
		new GameTypeModel('bj', 'Blackjack', 10, false),
		new GameTypeModel('craps', 'Craps', 10, false),
		new GameTypeModel('texas', 'Poker', 10, false),
	];

	@observable activeGame:GameInstanceModel|null = null;

}
