import { MiddleLayer } from "./server/MiddleLayer";
//Mock Comms
// import { MockServer } from "./server/MockServer";

import { HTTPServer } from "./server/HTTPServer";

var serverURI = 'https://dev.qbets.net:443'; //'https://mega.jgaming.net:9501';

if(window.gameConfig.serverURI){
	serverURI = window.gameConfig.serverURI;
}

export const comm = new MiddleLayer(new HTTPServer(serverURI));