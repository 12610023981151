import React from 'react';
import './App.css';
import { Main } from './components/Main';
import {Provider} from 'mobx-react';

import { Router, Route } from "react-router-dom";
import { store } from './store';
import { DemoModal } from './components/DemoModal';
import { DemoMenu } from './components/DemoMenu';
import styled from 'styled-components';
import { history } from './history';
import posed, { PoseGroup } from 'react-pose';

const PageAnimator = posed.div({
	enter: { opacity: 1, beforeChildren: true },
	exit: { opacity: 0 }
});

const Body = styled.div`
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-color: #f9f9f9;
	min-height: 100vh;
`
const App: React.FC = () => {
	return (
		<Body>
			<Provider mainStore={store}>
				<Router history={history}>
					<Route path="/" component={Main}/>
					<Route path="*/modal/demo" component={DemoModal}/>
					<PoseGroup animateOnMount={true}>
						<PageAnimator key={history.location.pathname}>
							<Route path="*/modal/menu" key="menu" component={DemoMenu}/>
						</PageAnimator>
					</PoseGroup>
				</Router>
			</Provider>
		</Body>
	);
}

export default App;
