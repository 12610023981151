import { Server } from "./iServer";
import { Choice, ClientRulesResponse } from "../store/models/GameInstanceModel";
import { Amount, NewGameChoice } from "../common";
import { ClientTCPGameResponse } from "../store/models/TCPGameModel";
import { BaccaratMiddleMixin } from "./BaccaratMiddleMixin";
import { DaiBaccMiddleMixin } from "./DaiBaccMiddleMixin";
import { PaiGowMiddleMixin } from "./PaiGowMiddleMixin";

//import UnorderedTopic from '../server/UnorderedTopic';
//import OrderedTopic from './server/OrderedTopic';

interface BJBet {
	seat:number,
	amount: number
}

export interface IServerResponse extends Response {
	ErrorCode: number,
	ErrorMessage: string
}

interface ServerTCPRulesResponse extends IServerResponse {
	Payload: {
		Rules: {
			RulesId: number,
			CreatedTime: number,
			DeletedTime: number,
			AnteMin: Amount
			AnteMax: Amount
			SixMin: Amount
			SixMax: Amount
			PairMin: Amount
			PairMax: Amount
		}
	}
}

interface ServerBlackjackRulesResponse extends IServerResponse {
	Payload: {
		Rules: {
			RulesId: number,
			CreatedTime: number,
			DeletedTime: number,
			Seats: number,
			Rules: any,
			Decks: number,
			MaxHands: number,
			BlackjackMultiple: number,
			InitialWager: {
				WagerMin: Amount
				WagerMax: Amount
				Payout: number
			}
		},
		NewGameChoices: NewGameChoice[][][]
	}
}

export interface ServerCard {
	CardId:number
	Abbr:string
}

export interface ServerTCPHand {
	"Cards": [ServerCard,ServerCard,ServerCard],
	"Rank": number
}

export interface ServerTCPGameResponse extends IServerResponse {
	Payload: {
		GameId: number
		State: string
		DealerHand: ServerTCPHand
		PlayerHands: [ServerTCPHand]
		Choices: {
			Type: string,
			Amount?: {
				Category: "currency",
				Type: "chip",
				Quantity: number
			}
		}[],
		Game: any
	}
}

export interface ClientBaccaratRulesResponse extends ClientRulesResponse {
	gameId: number,
}

class MiddleLayerCore {
	constructor(connection: Server) {
		this.connection = connection;
	}

	connection: Server;

	LoginTestUser = () => {
		return this.connection.CallRoute('login-load-test-user', {
			"PlatformUserId": "LOAD_2019492_144424_U1",
			"Username": "load_2019492_144424_u1"
	  });
	}

	GetBlackjackRules = (rulesId:number) => {
		return this.connection.CallRoute('blackjack-rules', {
			"RulesId": rulesId
		})
		.then((response:ServerBlackjackRulesResponse) => {

			var seats = response.Payload.NewGameChoices.map(seat => {
				var id = seat[0][0].SeatNum;
				var initialHandId = seat[0][0].HandId;
				var bets = seat.map(bet => {
					return {
						name: bet[0].BetName,
						min: Math.abs(bet[0].AmountMin.Quantity),
						max: Math.abs(bet[0].AmountMax.Quantity),
						choices: bet
					}
				})
				return {
					id: id,
					initialHandId: initialHandId,
					isPlayerPosition: true,
					bets: bets
				}
			});

			var formattedResponse:ClientRulesResponse = {
				seats: seats
			}
			return formattedResponse;
		});

	}

	BlackjackCreateGame = (rulesId:number, bets:{amount:number, handId:number,id:number}[]) => {

		var choices:any = [];
		bets.forEach(b => {
			choices.push({
				"SeatNum": b.id,
				"HandId": b.handId,
				"BetName": "main",
				"Type": b.amount ? "bet" : "pass",
				"Amount": {
					"Category": "currency",
					"Type": "chip",
					"Quantity": -b.amount
				}
			});
		})

		return this.connection.CallRoute('blackjack-create-game', {
			"Game": {
				"RulesId": rulesId,
			},
			"Choices": choices
		});
	}

	GetBlackjackGameState = (id:number) => {
		return this.connection.CallRoute('blackjack-game', {
			"GameId": id
		});
	}

	BlackjackChoice = (id:number, choice:Choice) => {
		return this.connection.CallRoute('blackjack-choice', {
			"GameId": id,
			Choices: [choice]
		});
	}

	//Baccarat

	//TCP End Points

	GetTCPRules = (rulesId:number) => {
		return this.connection.CallRoute('tcp-get-rules', {
			"RulesId": rulesId
		})
		.then((response:ServerTCPRulesResponse) => {
			var rules = response.Payload.Rules;
			var seats = [
				{
					id: 1,
					initialHandId: 1,
					isPlayerPosition: true,
					bets: [
						{
							name: 'ante',
							min: Math.abs(rules.AnteMin.Quantity),
							max: Math.abs(rules.AnteMax.Quantity),
						},
						{
							name: 'six',
							min: Math.abs(rules.SixMin.Quantity),
							max: Math.abs(rules.SixMax.Quantity),
						},
						{
							name: 'pair',
							min: Math.abs(rules.PairMin.Quantity),
							max: Math.abs(rules.PairMax.Quantity),
						},
					]
				}
			]
			var formattedResponse:ClientRulesResponse = {
				seats: seats
			};

			return formattedResponse;
		});
	}

	GetTCPGameState = (id:number) => {
		return this.connection.CallRoute('tcp-game', {
			"GameId": id
		});
	}

	TCPCreateGame = (rulesId:number, ante:number, six:number, pair:number) => {
		//Rules ID is currently hardcoded
		return this.connection.CallRoute('tcp-create-game', {
			"Game": {
				"RulesId": rulesId
			},
			"AnteAmount": {"Category": "currency", "Type": "chip", "Quantity": -ante},
			"SixAmount": {"Category": "currency", "Type": "chip", "Quantity": -six},
			"PairAmount": {"Category": "currency", "Type": "chip", "Quantity": -pair}
		})
		.then(this.TCPFormatGameResponse);
	}

	TCPResolveGame = (gameId:number, choice:Choice) => {
		//Rules ID is currently hardcoded
		return this.connection.CallRoute('tcp-resolve-game', {
			"GameId": gameId,
			"choice": choice
		})
		.then(this.TCPFormatGameResponse);
	}

	TCPFormatGameResponse = (response:ServerTCPGameResponse) => {
		const p = response.Payload;
		//p.PlayerHands[0].Rank
		var formatted:ClientTCPGameResponse = {
			GameId: p.GameId,
			State: p.State,
			DealerHand: p.DealerHand,
			Seats:[{
				SeatNum: 1,
				Hands: p.PlayerHands
			}],
			Choices: p.Choices ? [[p.Choices]] : [],
			Game: p.Game
		}
		return formatted;
	}
}


export const MiddleLayer = PaiGowMiddleMixin(
	DaiBaccMiddleMixin(
		BaccaratMiddleMixin(MiddleLayerCore)
	)
);

//Remove allowed, join, leave, set-invite
//Tournament Type: QT, FT