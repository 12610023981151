import {
	observable
} from "mobx"; //{ toJS }

export class GameTypeModel {

	constructor(id: string, name: string, players?:number, live?: boolean){
		this.id = id;
		this.name = name;
		this.players = players || 0;
		this.live = live || false;
	}

	@observable id = '';
	@observable name = '';
	@observable players = 0;
	@observable live = false;
}

