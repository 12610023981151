import React, {
	Component
} from "react";

const initialState = {
	component: null
};
type State = Readonly<typeof initialState>

export function asyncComponent(importComponent: any) {

	class AsyncComponent extends Component {

		readonly state: State = initialState

		async componentDidMount() {
			const exports = await importComponent();
			const keys = Object.keys(exports);
			if(keys.length !== 1){
				throw new Error('asyncComponent expected an import of a single')
			}
			const Component = exports[keys[0]];
			this.setState({
				component: Component
			});
		}

		render() {
			const C:any = this.state.component;

			return C ? <C {...this.props}/> : <div>loading...</div>;
		}
	}
	return AsyncComponent;
}