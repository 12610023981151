import React from 'react';
import { Route, RouteComponentProps, Switch, Redirect } from "react-router-dom";
import posed, { PoseGroup } from 'react-pose';

import { asyncComponent } from './AsyncComponent';
import styled from 'styled-components';

import {MdMenu} from 'react-icons/md';
import { makeModalPath, removeModalPath } from './Modals';
import { comm } from '../comm';

// const AsyncGameList = asyncComponent(() => import('./GameList'));
const AsyncHelp = asyncComponent(() => import('./Help'));
const AsyncBlackjack = asyncComponent(() => import('./Blackjack'));
const AsyncBaccarat = asyncComponent(() => import('./Baccarat'));
const AsyncDaiBacc = asyncComponent(() => import('./DaiBacc'));
const AsyncTCP = asyncComponent(() => import('./TCP'));
const AsyncPaiGow = asyncComponent(() => import('./PaiGow'));
const AsyncPaiGowPortrait = asyncComponent(() => import('./PaiGowPortrait'));

const PageAnimator = posed.div({
	enter: { opacity: 1, beforeChildren: true },
	exit: { opacity: 0 }
});

const MainBody = styled.div`
	display: flex;
	flex-direction: column;
	height: 100vh;
	@media (orientation: landscape){
		flex-direction: row;
	}
`

const MenuEdge = styled.div`
	flex: 0 0 auto;
	font-size: 24px;
	display: flex;
	padding: 8px;
	background-color: #333;
	color: #ccc;
	@media (orientation: landscape){
		justify-content: center;
	}
`

const Content = styled.div`
	flex-grow: 1;
	display: flex;
	overflow: hidden;
`

const PoseGroupStyled = styled(PoseGroup)`
	flex-grow: 1;
`


export class Main extends React.Component<RouteComponentProps> {

	state = {
		ready: false
	}

	openMenu = () => this.props.history.push(makeModalPath('menu'));

	constructor(props:RouteComponentProps){
		super(props);
		comm.LoginTestUser().then(() => {
			this.setState({ready: true});
		})
	}

	render(){
		return (
			<MainBody>
				<MenuEdge><MdMenu onClick={this.openMenu}/></MenuEdge>
				<Content id="MainContent">
					{this.state.ready ? (
						<PoseGroupStyled>
							<PageAnimator key={removeModalPath()}>
								<Switch>
									<Route path={`/blackjack`} key="BJ" component={AsyncBlackjack}/>
									<Route path={`/tcp`} key="TCP" component={AsyncTCP}/>
									<Route path={`/baccarat`} key="Baccarat" component={AsyncBaccarat}/>
									<Route path={`/daibacc`} key="DaiBacc" component={AsyncDaiBacc}/>
									<Route path={`/paigow/:ruleId`} key="PaiGow" component={AsyncPaiGow}/>
									<Route path={`/paigow-portrait/:ruleId`} key="PaiGow" component={AsyncPaiGowPortrait}/>
									<Route path={`/help`} key="Help" component={AsyncHelp}/>
									<Route path="/" key="GameList">
										<Redirect to="/paigow-portrait/2" />
									</Route>
								</Switch>
							</PageAnimator>
						</PoseGroupStyled>
					) : (
						<div>login...</div>
					)}
				</Content>
			</MainBody>
		);
	}
}