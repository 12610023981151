import { Bet, Choice } from "../store/models/GameInstanceModel";
import { Amount } from "../common";
import { Constructor } from "../helpers/Constructor";
import { IServerResponse } from "./MiddleLayer";


interface ICard {
	CardId: number
	Abbr: string
}

interface ServerDaiBaccBet {
	WagerMin: {
		Category: "currency"
		Type: "chip"
		Quantity: number
	}
	WagerMax: {
		Category: "currency"
		Type: "chip"
		Quantity: number
	},
	Payout: number
}

interface DaiBaccHand {
	HandId:number

	Cards: ICard[],
	Score: number
	Completed: boolean
	Wagers: any
	CardScores: number[]
	Natural: boolean
	Pair: boolean
}

interface HistoryEntry {

}

interface ServerDaiBaccRulesResponse extends IServerResponse {
	Payload: {
		Rules: {
			RulesId: number
			CreatedTime: number
			DeletedTime: number
			Seats: number
			Rules: {
				dai_bacc: true
			}
			Decks: number
			Player: ServerDaiBaccBet
			Banker: ServerDaiBaccBet
			SideBetRules: {
				KillOxTiger: ServerDaiBaccBet
				Tiger7: ServerDaiBaccBet
				Ox6: ServerDaiBaccBet
			}
		}
		TableHistory: HistoryEntry[]
	}
}

export interface ClientDaiBaccRulesResponse {
	Rules: {
		RulesId: number
		CreatedTime: number
		DeletedTime: number
		Seats: number
		Rules: {
			dai_bacc: true
		}
		Decks: number
		Player: ServerDaiBaccBet
		Banker: ServerDaiBaccBet
		SideBetRules: {
			KillOxTiger: ServerDaiBaccBet
			Tiger7: ServerDaiBaccBet
			Ox6: ServerDaiBaccBet
		}
	}
	TableHistory: HistoryEntry[]
}

interface Wager {
	WagerId: number,
	Amount: Amount,
	Resolution: {
		Type: "payout" | "resolution" | "push"
		Amount: Amount
	}
}

interface Seat {
	SeatNum: number,
	Hand: DaiBaccHand
	Score: number
	Wagers: {
		banker: Wager,
		Player: Wager,
		kill_ox_tiger: Wager
		ox_6: Wager,
		tiger_7: Wager
	}
}

interface DaiBaccChoiceServerResponse extends IServerResponse {
	Payload: DaiBaccChoiceResponsePayload
}

export interface DaiBaccChoiceResponsePayload {
	GameId: number
	State:string
	BankerHand: DaiBaccHand
	PlayerHand: DaiBaccHand
	Seats: Seat[]
	Choices: [
		[]
	]
	WaitingOnWagerIds: any
	Game: any
}

export interface ServerDaiBaccCreateResponse {
	Payload: {
		GameId:number
	}
}



export function DaiBaccMiddleMixin<TBase extends Constructor>(Base: TBase){

	return class extends Base {
		GetDaiBaccRules = (rulesId:number) => {
			//@ts-ignore
			return this.connection.CallRoute('baccarat-rules', {
				"RulesId": rulesId
			})
			.then((response:ServerDaiBaccRulesResponse) => {
				var formatted:ClientDaiBaccRulesResponse = response.Payload;
				return formatted;
			})
		}

		_DaiBaccRebet = null;

		DaiBaccCreateGame = (rulesId:number, bets:Bet[]) => {
			var choices:any = [];
			bets.forEach(b => {
				choices.push({
					"SeatNum": 1,
					"HandId": 1,
					"BetName": b.name,
					"Type": b.amount ? "bet" : "pass",
					"Amount": {
						"Category": "currency",
						"Type": "chip",
						"Quantity": -b.amount
					}
				});
			})

			return this.connection.CallRoute('baccarat-create-game', {
				"Game": {
					"RulesId": rulesId
				}
			})
			.then((response:ServerDaiBaccCreateResponse) => {
				return this.connection.CallRoute('baccarat-choice', {
					"GameId": response.Payload.GameId,
					"Choices": choices
				})
				.then((response:DaiBaccChoiceServerResponse) => {
					this._DaiBaccRebet = choices;
					return response.Payload;
				});
			});
		}

		DaiBaccRebet = (rulesId:number) => {
			if(!this._DaiBaccRebet){
				console.warn('Attempted to rebet DaiBacc with no data.');
				return;
			}
			var choices:any = this._DaiBaccRebet;

			return this.connection.CallRoute('baccarat-create-game', {
				"Game": {
					"RulesId": rulesId
				}
			})
			.then((response:ServerDaiBaccCreateResponse) => {
				return this.connection.CallRoute('baccarat-choice', {
					"GameId": response.Payload.GameId,
					"Choices": choices
				})
				.then((response:DaiBaccChoiceServerResponse) => {

					return response.Payload;
				});
			});


		}

		GetDaiBaccGameState = (id:number) => {
			return this.connection.CallRoute('baccarat-game', {
				"GameId": id
			});
		}

		DaiBaccChoice = (id:number, choice:Choice) => {
			return this.connection.CallRoute('baccarat-choice', {
				"GameId": id,
				Choices: [choice]
			});
		}
	}//end class

}


//Remove allowed, join, leave, set-invite
//Tournament Type: QT, FT