import { store } from "../store";
import { Server, Packet } from "./iServer";


export class HTTPServer implements Server {

	server:string

	constructor(server:string){
		this.server = server;
	}

	CallRoute(route:string, payload:any, retryCount:number = 0){
		store.commCount++;
		//Call a structured route
		let request:Packet = {
			"Route": route,
			"Payload": payload
		}
		return this.SendPacket(request, retryCount)
		.then((response:any)=>{
			if(response.ErrorCode && response.ErrorCode !== 0){
				console.error(response);
			}
			return response;
		}).catch((err) => {
			if(!retryCount || retryCount < 2){
				console.warn(`Will Retry (${retryCount + 1})`, err);
				this.CallRoute(route, payload, retryCount+1);
			} else {
				store.errorMessage = `Server communication failed at route: ${route} ${JSON.stringify(err)}`;
				throw err;
			}
		})
		.finally(() => {
			store.commCount--;
		});
	}

	// setSpinner(on){
	// 	if(this.timer && !on){
	// 		clearTimeout(this.timer);
	// 	} else {
	// 		this.timer = setTimeout(() => {
	// 			store.setSpinner(on);
	// 		}, 500);
	// 	}
	// }

	SendImages(route:string, images:any[]){
		var formData = new FormData();
		// console.log(images);
		for(var image in images){
			formData.append(image, images[image]);
		}

		return fetch(this.server + "/" + route, {
			method:'POST',
			body: formData
		})
		.then(response => {
			return response.json();
		});

	}

	token:number | undefined;
	cas:number | undefined;


	SendPacket(packet:Packet, retryCount:number){
		//@ts-ignore
		if(packet.RequestId){
			console.error("Packet Sent with restricted Attribute RequestId");
			return new Promise((resolve, reject) => {
				reject({errorCode: 1, errorMessage: "Restricted Attribute Sent with Packet: RequestId"});
			});
		}

		//let messageId = this.sentMessageId ? this.sentMessageId++ : this.sentMessageId = 1;
		//packet.RequestId = messageId;

		if(this.token){
			packet.Token = this.token;
		}
		if(this.cas){
			packet.Cas = this.cas;
		}


		return fetch(`${this.server}/interaction?route=${packet.Route}-try_${retryCount}`, {
			method:'POST',
			body: JSON.stringify(packet)
		})
		.then(response => {
			this.cas = undefined;
			return response.json()
			.then((data) => {
				if(data.Payload){
					if(data.Payload.Token){
						// console.log('token set', data.Payload.Token);
						this.token = data.Payload.Token
					}
					if(data.Payload.Cas){
						this.cas = data.Payload.Cas
					}
				}
				return Promise.resolve(data);
			});
		});
	}

	SendRaW(rawData:any){
		//This should not be used in production unless there is an extraordinary
		//There are rules for the server -> client communication

	}


}