import React from 'react';
import { store } from '../store';
import styled from 'styled-components';
import { history } from '../history';

const ModalBody = styled.div`
	position: absolute;
	top: 20%;
	bottom: 20%;
	left: 20%;
	right: 20%;
	background-color: #ccc;
	border-radius: 15px;
	box-shadow: 3px 3px 10px rgba(0,0,0,0.5);
	padding: 16px;
`

/**
 * @param path creates a clean path from the current url and appends ´path´.
 */
export function makeModalPath(path:string){
	return removeModalPath()+'/modal/'+path;
}

export function removeModalPath(){
	var path = window.location.pathname.split('/modal')[0];
	return path === '/' ? '' : path;
}

export function closeModal(){
	history.push(removeModalPath());
}

export const ModalCover = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0,0,0,0.5);
`

export class Modal extends React.Component{
	render(){
		return (
			<ModalBody>{this.props.children}</ModalBody>
		)
	}
}

export class PreemptiveModal extends React.Component {
	componentDidMount(){
		store.modal.canBackOut = false;
	}
	render(){
		return (
			<ModalBody>{this.props.children}</ModalBody>
		)
	}
}